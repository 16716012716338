export const BOOKING_STEPS = {
  EXTRAS: 'extras',
  DETAILS: 'details',
  PAYMENT: 'payment',
  PAYMENT_ACTION: 'payment-action',
  CONFIRMATION: 'confirmation',
};

export const HOLIDAY_TYPES = {
  EUROPEAN_HOLIDAY_PARKS: 'eurocaravan',
  BOATING_HOLIDAYS: 'boats',
  UK_LODGE_BREAKS: 'uklodge',
  UK_CAMPING_AND_TOURING_PARKS: 'uktouring',
  UK_COTTAGES: 'ukcottages',
  UK_HOLIDAY_PARKS: 'ukcaravan',
};

// Holiday type in here won't reset the date on duration change
export const CHANNELS_WHICH_DONT_RESET_DATE = [
  HOLIDAY_TYPES.BOATING_HOLIDAYS,
  HOLIDAY_TYPES.UK_CAMPING_AND_TOURING_PARKS,
  HOLIDAY_TYPES.UK_COTTAGES,
  HOLIDAY_TYPES.EUROPEAN_HOLIDAY_PARKS,
];

// Holiday types in here will filter available dates by holiday duration
export const CHANNELS_WITH_CUSTOM_FILTERING = [HOLIDAY_TYPES.UK_LODGE_BREAKS];

export const INTERNAL_LOCATIONS = ['/', 'https://www.breakfreeholidays', 'www.breakfreeholidays'];

export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const MAX_VALUE_ON_PRICE_RANGE = 1000;

export const FAKE_USER_DATA = {
  dobYr: '1993',
  addrLn3: '',
  tel: '07951787223',
  postOpt: 'Y',
  county: 'Essex',
  smsOpt: 'Y',
  country: 'CNNORTHE',
  title: 'Mr',
  dobDay: '20',
  dobMnth: '05',
  email: 'wakeling1000@hotmail.com',
  redeyeUrl:
    '//reporting.breakfreeholidays.co.uk/cgi-bin/rr/blank.gif?nourl=log-on&email=wakeling101%40hotmail.com&custref=562310',
  emailOpt: 'N',
  lastName: 'Wakeling',
  postCode: 'SS1 5UP',
  addrLn2: '',
  firstName: 'Danny',
  addrLn1: "112 Saint George's",
  thirdOpt: '',
  town: 'Leigh-on-sea',
};
