import { createSelector } from 'reselect';

import { searchResultsChannelIdSelector, searchResultsRegionIdSelector, searchResultsSelector } from './selectors';
import { channelsSelector } from '../../../data/channels/selectors';
import { parks, regionParkIds } from '../../../data/parks/selectors';
import { operators } from '../../../data/operators/selectors';
import { regionsSelector } from '../../../data/regions/selectors';

import { getChannelById } from '../../../data/channels/utils';
import { getParkById, getParksByRegionId } from '../../../data/parks/utils';
import { getOperatorById } from '../../../data/operators/utils';
import { getRegionById } from '../../../data/regions/utils';

// Selectors
export const currentParks = createSelector(parks, regionParkIds, searchResultsRegionIdSelector, (_parks, _regionParkIds, _regionId) => {
  return getParksByRegionId(_parks, _regionParkIds, _regionId);
});

export const currentRegion = createSelector(regionsSelector, searchResultsRegionIdSelector, (_regions, _regionId) => {
  return getRegionById(_regions, _regionId);
});

export const processedResults = createSelector(
  searchResultsChannelIdSelector,
  channelsSelector,
  regionsSelector,
  operators,
  parks,
  searchResultsSelector,
  (_channelId, _channels, _regions, _operators, _parks, _results) => {
    return _results.map((result) => {
      const park = getParkById(_parks, result.parkUrn);

      return {
        ...result,
        channel: getChannelById(_channels, _channelId),
        park,
        operator: getOperatorById(_operators, park?.opId),
        region: getRegionById(_regions, park?.regn),
      };
    });
  },
);
