import {
  channelRegionIdsSelector,
  getRegionsHasFailedSelector,
  getRegionsIsLoadedSelector,
  getRegionsIsLoadingSelector,
  regionsSelector,
} from '../../data/regions/selectors';

import { dateToDDMMYYYY } from '../../../utils/date';
import { sortBasedOnProp } from '../../../utils/utils';
import { getRegionsByChannelId } from '../../data/regions/utils';

export const searchFormChannelIdSelector = (state) => state.search.form.channelId;
export const searchFormRegionIdSelector = (state) => state.search.form.regionId;
export const searchFormArrivalDateSelector = (state) => state.search.form.arrivalDate;
export const searchFormDurationSelector = (state) => state.search.form.duration;
export const searchFormMonthSelector = (state) => state.search.form.month;
export const searchFormAdultsSelector = (state) => state.search.form.adults;
export const searchFormChildrenSelector = (state) => state.search.form.children;
export const searchFormUnder5sSelector = (state) => state.search.form.under5s;
export const searchFormCottageDatesSelector = (state) => state.search.form.cottageDates;

export const searchFormPartySizeSelector = (state) => {
  return state.search.form.adults + state.search.form.children + state.search.form.under5s;
};

export const searchFormPetFriendlySelector = (state) => {
  return searchFormChannelIdSelector(state) === 'eurocaravan' ? undefined : state.search.form.petFriendly;
};

export const searchFormParamsSelector = (state) => ({
  channelId: searchFormChannelIdSelector(state),
  regionId: searchFormRegionIdSelector(state),
  arrivalDate: searchFormArrivalDateSelector(state),
  duration: searchFormDurationSelector(state),
  adults: searchFormAdultsSelector(state),
  children: searchFormChildrenSelector(state),
  under5s: searchFormUnder5sSelector(state),
  petFriendly: searchFormPetFriendlySelector(state),
});

export const searchFormRequestParamsSelector = (state) => ({
  channel: searchFormChannelIdSelector(state),
  region: searchFormRegionIdSelector(state),
  date: dateToDDMMYYYY(new Date(searchFormArrivalDateSelector(state))),
  partysize: searchFormAdultsSelector(state) + searchFormChildrenSelector(state) + searchFormUnder5sSelector(state),
  nights: searchFormDurationSelector(state),
  adults: searchFormAdultsSelector(state),
  children: searchFormChildrenSelector(state),
  infants: searchFormUnder5sSelector(state),
  dogs: searchFormPetFriendlySelector(state) ? 'y' : '',
});

export const searchFormRegionsLoadingSelector = (state) =>
  getRegionsIsLoadingSelector(state, searchFormChannelIdSelector(state));

export const searchFormRegionsNeedToBeLoadedSelector = (state) => {
  const _channelId = searchFormChannelIdSelector(state);

  return (
    _channelId &&
    !getRegionsIsLoadingSelector(state, _channelId) &&
    !getRegionsIsLoadedSelector(state, _channelId) &&
    !getRegionsHasFailedSelector(state, _channelId)
  );
};

const EUROCARAVAN_SORT_ORDER = [
  'France: North',
  'France: Central',
  'France: South & Corsica',
  'Spain',
  'Italy: North',
  'Italy: Central & Sardinia',
  'Croatia',
  'Netherlands & Luxembourg',
  'Germany',
  'Austria & Switzerland',
  'Portugal',
  'Slovenia',
];

export const searchFormCurrentRegionsSelector = (state, newChannel = false) => {
  const channelToUse = newChannel || searchFormChannelIdSelector(state);
  const regionsByChannelId = getRegionsByChannelId(
    regionsSelector(state),
    channelRegionIdsSelector(state),
    channelToUse,
  );
  if (channelToUse === 'ukcaravan') {
    regionsByChannelId.unshift({
      id: 'all-regions',
      name: 'All of the UK',
      dogs: 'Y',
    });
  }
  if (channelToUse === 'eurocaravan') {
    return sortBasedOnProp(regionsByChannelId, EUROCARAVAN_SORT_ORDER, 'name');
  }
  return regionsByChannelId?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};
