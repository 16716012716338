import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { config } from '../../../config';
import t from '../../../text';
import { usePageWidth } from '../../hooks/usePageWidth';
import { minPricesMonthsSelector } from '../../redux/data/cachedAvail/selectors';
import { selectChannel, selectMonth } from '../../redux/search/form/actions';
import { searchFormArrivalDateSelector, searchFormMonthSelector, searchFormParamsSelector } from '../../redux/search/form/selectors';
import { useNewsletter } from '../Footer/NewsletterContext';
import { ChannelSelect } from './ChannelSelect';
import { DateDurationSelect } from './DateDurationSelect';
import { GuestsSelect } from './GuestsSelect';
import { RegionSelect } from './RegionSelect';
import { useHandleSearch } from './useHandleSearch';

const COL_CLASS_NAME = 'px-lg-1 px-xl-2';

export const SearchBox = ({ isHeroPage, includeEmail, isHomePage }) => {
  const dispatch = useDispatch();
  const { width } = usePageWidth();

  const params = useSelector(searchFormParamsSelector);
  const month = useSelector(searchFormMonthSelector);
  const arrivalDate = useSelector(searchFormArrivalDateSelector);

  const _minPricesMonths = useSelector(minPricesMonthsSelector);

  const { hasSignedUpForDeals } = useNewsletter();

  const showSignUpUI = includeEmail && !hasSignedUpForDeals;

  const [email, setEmail] = useState('');
  const [showSearchModal, setShowSearchModal] = useState(false);
  const toggleSearchModal = () => setShowSearchModal((show) => !show);

  const { handleSearch } = useHandleSearch();

  const formSubmit = (event) => {
    setShowSearchModal(false);
    handleSearch(event, email);
  }

  useEffect(() => {
    if (window.location.pathname === '/') {
      selectChannel(dispatch, 'ukcaravan');
    }

    if (!month && !arrivalDate) {
      selectMonth(dispatch, _minPricesMonths[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On a mobile the search box opens in a modal, now that we have resized to medium we want to close it
  useEffect(() => {
    if (width < 768) return;
    if (showSearchModal) setShowSearchModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const className = isHomePage ? '' : 'bg-dark py-4';
  return (
    <Container fluid className={className}>
      <Button className="d-md-none" block variant="primary" onClick={toggleSearchModal}>
        {t('search.search')}
      </Button>

      <Container className={`search-box d-none d-md-block ${showSearchModal ? 'is-open' : ''}`} id="search-modal">
        <i className="icon-close h2 close-search-modal d-inline-block d-md-none" onClick={toggleSearchModal} />

        <Form onSubmit={formSubmit}>
          <Row xs={1}>
            <Col lg={10}>
              <Row>
                <Col md={6} lg className={COL_CLASS_NAME}>
                  <ChannelSelect />
                </Col>
                <Col md={6} lg className={COL_CLASS_NAME}>
                  <RegionSelect />
                </Col>
                <Col md={6} lg className={COL_CLASS_NAME}>
                  <DateDurationSelect />
                </Col>
                <Col md={6} lg className={COL_CLASS_NAME}>
                  <GuestsSelect />
                </Col>
                {showSignUpUI && (
                  <Col md={6} lg className={COL_CLASS_NAME}>
                    <Form.Group controlId="EmailAddress">
                      <Form.Label className="text-white">{t('search.dealSignup')}</Form.Label>
                      <Form.Control
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder="Email address"
                        className="mt-md-0 toggle-popup"
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('footer.newsletter.invalidEmail')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Col>

            <Col lg={2} className={`pr-lg-1 px-xl-2 ${isHeroPage && 'mt-2'}`}>
              {!isHeroPage && <Form.Label>&nbsp;</Form.Label>}
              <Button
                block
                variant={config['searchBox.buttonColour']}
                type="submit"
                disabled={
                  !params.channelId ||
                  !params.regionId ||
                  !params.arrivalDate ||
                  !params.duration ||
                  params.adults === 0
                }
              >
                {t('search.search')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Container>
  );
};

SearchBox.propTypes = {
  isHeroPage: PropTypes.bool,
  includeEmail: PropTypes.bool,
  isHomePage: PropTypes.bool,
};

SearchBox.defaultProps = {
  isHeroPage: false,
  includeEmail: false,
  isHomePage: false,
};
