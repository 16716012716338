import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { region } from '../../redux/pages/browseParks/selectors';
import { currentRegion } from '../../redux/search/results/selectors/results';
import useFetchData from '../Content/TravelHooks/useFetchData';
import { useGetTravelGuideData } from '../HolidayPark/useGetTravelGuideData';
import { useLocation } from 'react-router-dom';

const ThingsToDoContext = createContext();

export const ThingsToDoProvider = ({ children }) => {
  const _currentRegion = useSelector(currentRegion);
  const _region = useSelector(region);

  const location = useLocation();
  const isSearchResult = /(park-result|boat-result|results)/.test(location.pathname);

  const regionName = isSearchResult ? _currentRegion?.name : _region?.name;
  
  const { ttdData } = useGetTravelGuideData(regionName);
  const posts = useFetchData(ttdData, 'things-to-do');

  const postsData = ttdData.length > 0 ? posts : [];

  return <ThingsToDoContext.Provider value={{ postsData }}>{children}</ThingsToDoContext.Provider>;
};

export const useThingsToDoContext = () => useContext(ThingsToDoContext);

ThingsToDoProvider.propTypes = {
  children: PropTypes.node,
};
